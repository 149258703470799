import { Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import './headerNavBar.css';
function ContainerOutsideExample() {
  const navigate = useNavigate();
  return (
    <>
  <nav class="navbar navbar-expand-lg  custom-navbar container-fluid"  >
  <div class="container-fluid">
    <a class="navbar-brand " style={{color:"whitesmoke" , paddingLeft:'30px'}} onClick={()=>navigate("/")}>Azteck Solutions</a>
    
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" >
      <span class="navbar-toggler-icon" ></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end container-fluid nav-underline" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <Button style={{color:"whitesmoke" ,background:"none" , border:"0px"}} onClick={()=>navigate("/")}>Home</Button>
        </li>
        <li class="nav-item">
          <Dropdown >
            <Dropdown.Toggle id="dropdown-basic" style={{background:"none" , border:"0px"}} >
                Services
            </Dropdown.Toggle>
            <Dropdown.Menu style={{backgroundColor:"black"}}>
                <Dropdown.Item as="button" style={{color:"white"}}>Action</Dropdown.Item>
                <Dropdown.Item as="button" style={{color:"white"}}>Another action</Dropdown.Item>
                <Dropdown.Item as="button" style={{color:"white"}}>Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        <li class="nav-item">
          <Button style={{color:"whitesmoke", background:"none" , border:"0px"}}>Contact Us</Button>
        </li>
        <li class="nav-item">
        <Button variant="outline-primary">Book Your Call</Button>{' '}
        </li>
      </ul>
    </div>
  </div>
</nav>
    
    </>
  );
}

export default ContainerOutsideExample;