import React from 'react'
import Section1 from './Section1/section1'
import Section2 from './Section2/Section2'
import Section3 from './Section3/Section3'
import Section4 from './Section4/Section4'
import Section5 from './Section5/Section5'
const Home = () => {
  return (
    <>
    <div>
        <Section1/>
    </div>
    <div>
        <Section2/>
    </div>
    <div>
        <Section3/>
    </div>
    <div>
        <Section4/>
    </div>
    <div>
        <Section5/>
    </div>
    </>
  )
}  
export default Home
