import React from 'react'
import "./section1.css";
const section1 = () => {
  return (
    <div className='container-fluid s1'>
        <div className='Text container'>
          <p class="h1">Where Vision Meets Technology</p>
          <p class="h5" style={{paddingTop:"10px"}}>Your Vision, Our Code – Transforming Ideas into Impactful Digital Solutions.</p>
          <p class="h5"> Our mission is to bring your vision to life by crafting innovative, reliable, and</p>
          <p class="h5"> scalable software solutions that make an impact.</p>
          <div className='buttonContact'>
            <button className='ContactButton'>Enquire Now</button>
          </div>
        </div>

    </div>
  )
}

export default section1
