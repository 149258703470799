import React from 'react'
import './Section4.css';
const Section4 = () => {
  return (
    <div className='container-fluid s4'>
    <div className='Texts4 container'>
    <p className='h1' style={{color:"white"}}>How We Work</p>
    <p className='h5' id='p4Text'>  our approach is rooted in collaboration, clarity, and commitment.  </p>
    <p className='h5' id='p4Text'>We work closely with you from the very first conversation to understand your needs and bring your vision to life. </p>
    <p className='h5' id='p4Text'>innovative Solutions that drive business success. </p>
    <div className='buttonContacts4'>
            <button className='ContactButtons4'>Discover Your Solution</button>
    </div>
    </div>
    
</div>
  )
}

export default Section4
