import React from 'react'
import Navbar from './Navbar/headerNavBar'
import Home from './HOME/Home'
import PageFooter from './Footer/pageFooter'
import { BrowserRouter as Router, Routes, Route , Navigate  } from 'react-router-dom';

import WebDevService from './ServicePage/WebDevService/WebDevService'

const App = () => {
  return (
    <Router>
      <div className='Appdiv'>
        <Navbar/>
          <Routes>
            <Route exact path ='/' element={<Home/>}></Route>
            <Route exact path ='/WebDevService' element={<WebDevService/>}></Route>
          </Routes>
          <PageFooter/>
      </div>
    </Router>
  )
}

export default App
