import React from 'react'
import './WebDevservice.css'
import { Accordion, Badge, Col, Container, Image, Row, Stack } from 'react-bootstrap'
const WebDevService = () => {
  return (
    <>
        <div className='container-fluid wd1' style={{ backgroundImage: `url(${require("../../assets/images/webdevservicebg.jpg")})` }} >
            <div className='Textwd1 container'>
                <p class="h1">Web Developemnt</p>
                <p class="h5" style={{paddingTop:"10px"}}>Your Vision, Our Code – Transforming Ideas into Impactful Digital Solutions.</p>
                <div className='buttonContact'>
                    <button className='ContactButton'>Enquire Now</button>
                </div>
            </div>  
        </div>
        <div className='conatiner-fluid'>
            <p className="h1 workflowHeading">Steps we take</p>
            <Container fluid  className='StepsMainContainer'>
                <Col className='text-white stepscontainerCol' >
                    <Row className='mx-5 steprow'>
                        <Stack direction="horizontal" gap={2}>
                        <Badge bg="primary">1</Badge><h5>Gather Requirements</h5>
                        </Stack>
                    </Row>
                    <Row className='mx-5 steprow'>
                        <Stack direction="horizontal" gap={2}>
                            <Badge bg="primary">2</Badge>
                            <h5>identify target sector</h5>
                        </Stack>
                    </Row>
                    <Row className='mx-5 steprow'>
                        <Stack direction="horizontal" gap={2}>
                            <Badge bg="primary">3</Badge>
                            <h5>conduct a competitor analysis</h5>
                        </Stack>
                    </Row>
                    <Row className='mx-5 steprow'>
                        <Stack direction="horizontal" gap={2}>
                            <Badge bg="primary">4</Badge>
                            <h5>conduct a techinal seo analysis</h5>
                        </Stack>
                    </Row>
                    <Row className='mx-5 steprow'>
                        <Stack direction="horizontal" gap={2}>
                            <Badge bg="primary">5</Badge>
                            <h5>create a prototype design</h5>
                        </Stack>
                    </Row>
                    <Row className='mx-5 steprow'>
                        <Stack direction="horizontal" gap={2}>
                            <Badge bg="primary">6</Badge>
                            <h5>Move to production build</h5>
                        </Stack>
                    </Row>
                </Col>
            </Container>
        </div>
        <div className='conatiner-fluid'>
            <p className="h1 workflowHeading">Workflow</p>
            <Accordion  className='DropHeader' defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item className='DropHeader' eventKey="0">
                    <Accordion.Header> <b> Step 1: Initial Meeting & Plan</b></Accordion.Header>
                    <Accordion.Body>                     
                    The first step in our website development process is a focused strategy session, where we  
                    discuss your website’s purpose, your brand identity, and your goals for the online presence. During this meeting, 
                    we take the time to understand your core values, target audience, and specific requirements for functionality and features, 
                    whether it's an e-commerce platform, a blog, or a business site. We explore essential elements like design preferences, 
                    content needs, and any integration requirements to ensure the website aligns seamlessly with your brand. By the end of this 
                    step, we have a clear, customized roadmap that sets the foundation for a website that captures your vision and engages your audience effectively.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className='DropHeader'  eventKey="1">
                    <Accordion.Header ><b> Step 2: In-Depth Research & Mockup Design</b></Accordion.Header>
                    <Accordion.Body>
                    In the second step, we dive into detailed research and design to bring your website vision to life. First, 
                    we conduct thorough market and competitor analysis to identify trends, best practices, and unique design 
                    elements that will help your website stand out. This includes assessing user behaviors, analyzing target 
                    audience preferences, and understanding the latest UX/UI trends relevant to your industry. <br/><br/>With these insights 
                    in hand, our design team creates a mockup—a visual prototype that provides a clear picture of the website’s 
                    layout, structure, and user journey. The mockup outlines key components like navigation, content placement, 
                    imagery, and calls-to-action, giving you a preview of how the website will look and feel. We then review this
                    mockup with you, incorporating your feedback to ensure it aligns with your expectations and brand identity.
                    This step ensures that the final design is both visually appealing and strategically structured to deliver a 
                    seamless user experience.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item  className='DropHeader' eventKey="2">
                    <Accordion.Header> <b> Step 3: Build , Test , Deliever</b></Accordion.Header>
                    <Accordion.Body>
                    In the final stage, we transition from planning to execution, turning the approved design into a fully 
                    functional website. Our development team begins the build phase, where we code and integrate all features, 
                    ensuring responsiveness across devices, seamless navigation, and optimal loading speeds. We pay close attention 
                    to quality and detail, from backend functionality to frontend design, to ensure a cohesive and polished 
                    experience. <br/><br/>
                    Once the build is complete, we move to testing. Here, we conduct rigorous quality assurance, checking for 
                    functionality, compatibility, security, and usability. This includes cross-browser testing, mobile 
                    responsiveness, and stress testing to ensure the website performs reliably under various conditions. 
                    We also test for any possible bugs or errors, making necessary adjustments to ensure a smooth user experience.<br/><br/>
                    Finally, we reach the delivery phase, where we prepare your website for launch. After a final review and 
                    approval from you, we deploy the website on your preferred hosting platform, ensuring everything is configured 
                    correctly for a successful live launch. Post-launch, we remain available to support you, offering training and 
                    ongoing maintenance options to keep your website running smoothly. This comprehensive approach ensures a 
                    high-quality, ready-to-go website that aligns with your vision and is built to drive results.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </>
  )
}

export default WebDevService
