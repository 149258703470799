import React from 'react'
import './Section2.css';
const Section2 = () => {
  return (
   <div className='container-fluid s2'>
        <div className='Texts2 container'>
        <p className='h1' style={{color:"white"}}>Azteck Core Services</p>
        <p className='h5' id='pText'> we specialize in a wide range of software development services designed to meet the </p>
        <p className='h5' id='pText'>diverse needs of modern businesses. Our team is committed to deliver high-quality, </p>
        <p className='h5' id='pText'>innovative Solutions that drive business success. </p>
        </div>
    </div>
   
  )
}

export default Section2

/*

Our core offerings include custom software development, where we tailor applications to match unique business processes and goals; 
web and mobile app development to create seamless, user-friendly experiences across platforms; and cloud solutions that provide
 secure, scalable, and flexible infrastructure for today's digital demands. Additionally, we offer system integration services to 
 streamline operations by connecting disparate technologies, UI/UX design to ensure engaging, intuitive interfaces, and ongoing 
 support and maintenance to keep your software optimized. Whatever your vision, 
 AI-driven solutions like chatbots and intelligent automation to enhance user interactions and operational efficiency. 
 We also specialize in data analytics, enabling businesses to gain actionable insights from their data
Azteck Solutions is here to develop high-quality, innovative solutions that drive your business forward.


*/