import React from 'react'
import "./Section5.css";


import cardImag1 from "../../assets/images/c1.png";
import cardImag2 from "../../assets/images/c2.png";
import cardImag3 from "../../assets/images/c3.png";
import { Card , CardGroup} from 'react-bootstrap';

const Section5 = () => {
  return (
    <div className='container-fluid s5'>
        <CardGroup>
            <Card className='workCard'>
                <Card.Img variant="top" src={cardImag1} />
                <Card.Body>
                <Card.Title className='textColor' ><b>1.Discover & Define</b></Card.Title>
                <Card.Text  className='textColor'>
                 Our journey with you begins with a <b>Free Consultation</b> to understand your vision and business goals. During this phase, we dive deep to grasp your challenges, requirements, and aspirations, ensuring we’re aligned on every detail. This step sets a clear foundation, paving the way for a customized solution that’s built around your needs.
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                <small className="" className="cardFooterText" >Start your discovery</small>
                </Card.Footer>
            </Card>
            <Card className='workCard'>
                <Card.Img variant="top" src={cardImag2} />
                <Card.Body>
                <Card.Title className='textColor' >2.Strategize & Design</Card.Title>
                <Card.Text className='textColor'>
                Based on our initial discussions, we craft a tailored Proposal of Design and Solution that includes strategic insights, design mockups, and a roadmap for development. This proposal gives a clear view of the process, including objectives, timelines, and key milestones, allowing us to collaboratively shape the solution and confirm the approach before moving forward.
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                <small className="cardFooterText">Ready to discuss your strategy?</small>
                </Card.Footer>
            </Card>
            <Card className='workCard'>
                <Card.Img variant="top" src={cardImag3} />
                <Card.Body>
                <Card.Title className='textColor'>3.Build & Deliver</Card.Title>
                <Card.Text className='textColor'>
                With the proposal approved, we get to work in the Execution Phase, where our team brings your vision to life. From development and testing to final deployment, we handle each step with precision and ongoing communication. Our goal is to deliver a seamless, high-quality solution that’s aligned with your expectations, ready to drive impact and success for your business.
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                <small className="cardFooterText">Ready to implement?</small>
                </Card.Footer>
            </Card>
    </CardGroup>
    </div>

    
  )
}
export default Section5


/*
 <div className=' container-fluid s5'>
<div className='container cs5'>
            <div className="row g-0 bg-body-secondary position-relative card1">
                    <div class="col-md-6 mb-md-0 p-md-4 cardImage">
                        <img src={cardImag1} className="w-100 cardImageTag"  alt="..."/>
                    </div>
                    <div className="col-md-6 p-4 ps-md-0 step1CardText">
                        <h4 class="mt-0">1.Discover & Define</h4>
                        <p className='h6'>Our journey with you begins with a <b>Free Consultation</b> to understand your vision and business goals. During this phase, we dive deep to grasp your challenges, requirements, and aspirations, ensuring we’re aligned on every detail. This step sets a clear foundation, paving the way for a customized solution that’s built around your needs.</p>
                    </div>
            </div>
            <div className="row g-0 bg-body-secondary position-relative card2">
                    <div class="col-md-6 mb-md-0 p-md-4 cardImage" >
                        <img src={cardImag2} className="w-100 cardImageTag" alt="..."/>
                    </div>
                    <div className="col-md-6 p-4 ps-md-0 step1CardText">
                        <h4 class="mt-0">2.Strategize & Design</h4>
                        <p className='h6'>Based on our initial discussions, we craft a tailored Proposal of Design and Solution that includes strategic insights, design mockups, and a roadmap for development. This proposal gives a clear view of the process, including objectives, timelines, and key milestones, allowing us to collaboratively shape the solution and confirm the approach before moving forward.</p>
                    </div>
            </div>
            <div className="row g-0 bg-body-secondary position-relative card3">
                    <div class="col-md-6 mb-md-0 p-md-4 cardImage">
                        <img src={cardImag3} className="w-100 cardImageTag" alt="..."/>
                    </div>
                    <div className="col-md-6 p-4 ps-md-0 step1CardText">
                        <h4 class="mt-0">3.Build & Deliver</h4>
                        <p className='h6'>With the proposal approved, we get to work in the Execution Phase, where our team brings your vision to life. From development and testing to final deployment, we handle each step with precision and ongoing communication. Our goal is to deliver a seamless, high-quality solution that’s aligned with your expectations, ready to drive impact and success for your business.</p>
                    </div>
            </div>
        </div>


*/