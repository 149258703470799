import React from 'react'
import './pageFooter.css'
import { Col, Container, Image, Nav, NavLink, Row, Stack } from 'react-bootstrap'
import logo from "../assets/images/MainLogo.png"
const footer = () => {
  return (
    <footer>
      <Container fluid >
        <Row className='text-white footerRow'>
          <Col className='mx-5' id="footerCol">
              <Stack>
                  <Image src={logo} alt="comapny Logo" rounded width={120} height={90}/>
                  <h5>Where vision meets technology</h5>
              </Stack>
          </Col>
          <Col className='mx-5' id="footerCol">
              <Nav className='flex-column fs-5'>
                  <h4>Useful Links</h4>
                 <NavLink href="#" className='text-white'>Home</NavLink>
                 <NavLink href="#" className='text-white'>Services</NavLink>
                 <NavLink href="#" className='text-white'>Contact Us</NavLink>
              </Nav>
          </Col>
          <Col className='mx-5' id="footerCol">
            <h4>Contact Us</h4>
            <p>info@aztecksolutions.com</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default footer
