import React from 'react';
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';


import './index3.css'
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import webDevIcon from '../../assets/images/webDevicon.gif';
import webDesignIcon from '../../assets/images/webDesignIcon.gif';
import uiuxIcon from '../../assets/images/uiuxIcon.gif';
import aiIcon from '../../assets/images/aiIcon.gif';
import dataIcon from '../../assets/images/dataIcon.gif';
import ErpIcon from '../../assets/images/ErpIcon.gif';


function Section3() {
  const navigate = useNavigate();
  return (
    <div className='container-fluid' id="MainServicesContainer">
    <div className="container" id='servicesContainer'>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{el:'.swiper-pagination',clickable:true}}
        navigation={{nextEl:'.swiper-button-next',prevEl:'.swiper-button-prev',clickable:true,}}
        modules={[EffectCoverflow,Pagination,Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
        <div className="card" id="ServiceCard" >
            <img src={webDevIcon} id="cardServiceImg" alt='icon'/>
            <div className="card-body">
                <h5 className="card-title" id="cardHeadingText">Web Development</h5>
                <p className="card-text" id='cardserviceText'>Our Web Development service at Azteck Solutions is dedicated to building high-performance, secure, and visually compelling websites that drive user engagement and business growth. From corporate websites to e-commerce platforms and custom web applications, our team combines cutting-edge technologies with industry best practices to deliver seamless, responsive, and scalable digital experiences. We focus on user-centered design, ensuring intuitive navigation and optimized performance across all devices.</p>
                <a href="#" className="btn btn-primary" id='cardButton' onClick={()=> navigate("/WebDevService")}>Discover</a>
            </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>  
        <div className="card" id="ServiceCard" >
            <img src={webDesignIcon} id="cardServiceImg" alt='icon'/>
            <div className="card-body">
                <h5 className="card-title" id="cardHeadingText">Web Design</h5>
                <p className="card-text" id='cardserviceText'>Our Web Design service at Azteck Solutions focuses on creating visually engaging, user-friendly websites that reflect your brand’s identity and capture the attention of your target audience. We combine strategic layout design, eye-catching graphics, and intuitive navigation to craft a seamless user experience that is both aesthetically pleasing and highly functional.</p>
                <a href="#" className="btn btn-primary" id='cardButton'>Discover</a>
            </div>
        </div>

        </SwiperSlide>
        {/*<SwiperSlide>
        <div className="card" id="ServiceCard" >
            <img src={uiuxIcon} alt='icon' id="cardServiceImg"/>
            <div className="card-body">
                <h5 className="card-title" id="cardHeadingText">Ui/UX</h5>
                <p className="card-text" id='cardserviceText'>Our UI/UX Design service at Azteck Solutions is dedicated to crafting intuitive, engaging, and aesthetically pleasing interfaces that prioritize user experience at every touchpoint. We take a research-driven approach to understand your users’ needs, preferences, and behaviors, translating these insights into designs that make navigating your digital products a seamless and enjoyable experience.</p>
                <a href="#" className="btn btn-primary" id='cardButton'>Discover</a>
            </div>
        </div>
        </SwiperSlide>*/}
        <SwiperSlide>
        <div className="card" id="ServiceCard" >
            <img src={aiIcon} alt='icon' id="cardServiceImg"/>
            <div className="card-body">
                <h5 className="card-title" id="cardHeadingText">AI Solutions</h5>
                <p className="card-text" id='cardserviceText'>Our AI Solutions service at Azteck Solutions harnesses the power of artificial intelligence to deliver intelligent, data-driven applications that elevate your business capabilities. We specialize in developing custom AI models, chatbots, predictive analytics, and automation solutions tailored to meet the unique challenges of your industry. By leveraging machine learning, natural language processing, and advanced algorithms, we help you uncover valuable insights, streamline operations, and provide enhanced customer experiences.</p>
                <a href="#" className="btn btn-primary" id='cardButton'>Discover</a>
            </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="card" id="ServiceCard" >
            <img src={dataIcon} alt='icon' id="cardServiceImg"/>
            <div className="card-body">
                <h5 className="card-title" id="cardHeadingText">Data Analytics</h5>
                <p className="card-text" id='cardserviceText'>Our Data Analytics service at Azteck Solutions empowers businesses to transform raw data into actionable insights. We offer comprehensive analytics solutions that include data visualization, predictive analytics, data mining, and business intelligence to help you make informed, strategic decisions. By organizing, analyzing, and interpreting data, we uncover trends, patterns, and opportunities that drive business growth and improve operational efficiency.</p>
                <a href="#" className="btn btn-primary" id='cardButton'>Discover</a>
            </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="card" id="ServiceCard" >
            <img src={ErpIcon} alt='icon' id="cardServiceImg"/>
            <div className="card-body">
                <h5 className="card-title" id="cardHeadingText">ERP systems</h5>
                <p className="card-text" id='cardserviceText'>Our ERP Systems service at Azteck Solutions is designed to streamline and unify your business processes under a single, integrated platform. We provide customized ERP solutions that encompass all core functions—finance, inventory management, HR, sales, and supply chain—allowing you to manage and monitor operations in real time. With a focus on scalability, security, and ease of use, our ERP systems are tailored to fit the specific needs of your organization, driving efficiency and reducing costs.</p>
                <a href="#" className="btn btn-primary" id='cardButton'>Discover</a>
            </div>
        </div>
        </SwiperSlide>

         
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
    </div>
  );
}

export default Section3;